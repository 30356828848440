<template>
    <div>
        <div class="field">
            <label class="label" for="title">{{ $t('products.quotation.title') }}</label>
            <div class="field-control">
                <input id="title" type="text" v-model="product.quotation_title">
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('products.quotation.description') }}</label>
            <div class="field-control">
                <textarea placeholder="" v-model="product.quotation_description"></textarea>
            </div>
        </div>
        <div class="field">
            <label class="label" for="button">{{ $t('products.quotation.button') }}</label>
            <div class="field-control">
                <input id="button" type="text" v-model="product.quotation_button">
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('products.quotation.worker') }}</label>
            <div class="field-control">

                <multiselect v-model="product.quotation_worker"
                             :options="workers"
                             :multiple="false"
                             track-by="id"
                             @search-change="searchWorkers"
                             :loading="fetchingWorkers"
                             placeholder="Selecteer werker">
                    <template slot="option" slot-scope="{ option }">
                        <span>{{ option.first_name }} {{option.last_name}}</span>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                        <span>{{ option.first_name }} {{option.last_name}}</span>
                    </template>
                </multiselect>
            </div>
        </div>

        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('products.save_quotation') }}
        </a>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import { ProductService, WorkerService } from '../../../services';

    export default {
        components: { Multiselect },
        computed: {
            product() {
                return this.$store.state.products.product;
            },
        },
        data() {
            return {
                workers: [],
                fetchingWorkers: false,
            }
        },
        mounted() {
            this.getWorkers();
        },
        methods: {
            async getWorkers(query = null) {
                this.fetchingWorkers = true;

                const params = {
                    filters: {
                        name: query,
                    },
                };

                const workers = await WorkerService.index(params);
                this.workers = await workers.data.data;

                this.fetchingWorkers = false;
            },
            searchWorkers(query) {
                this.getWorkers(query);
            },
            save() {
                this.$store.commit('products/set_product', this.product);

                this.$store.dispatch('products/update_product').then(response => {
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
        name: 'ProductQuotationTab',
    };
</script>
