import axios from 'axios';

const BaseService = {
    get(path, params, options) {
        return axios.get(`${path}`, { params, ...options });
    },

    post(path, params, headers, options = {}) {
        headers = headers || {};

        return axios.post(`${path}`, params, Object.assign({ headers }, options));
    },

    put(path, params) {
        return axios.put(`${path}`, params);
    },

    patch(path, params, options = {}) {
        return axios.patch(`${path}`, params, options);
    },

    delete(resource, options = {}) {
        return axios.delete(resource, options);
    }
};

export default BaseService;

export { default as ProductService } from './products';
export { default as ConfigurationService } from './configurations';
export { default as ArticleService } from './articles';
export { default as SpecificationService } from './specifications';
export { default as ProsAndConsService } from './pros-and-cons';
export { default as RelevantProductService } from './relevant-products';
export { default as RelatedProductGroupService } from './related-products-groups';
export { default as AccessoryService } from './accessories';
export { default as FilterService } from './filters';
export { default as CategoryService } from './categories';
export { default as DiscountCodeService } from './discount-codes';
export { default as NewsArticleService } from './news-articles';
export { default as ChoiceHelpService } from './choice-help';
export { default as ChoiceHelpAnswerService } from './choice-help-answers';
export { default as RedirectService } from './redirects';
export { default as StaticPageService } from './static-page';
export { default as DividerService } from './dividers';
export { default as FaqQuestionsService } from './faq-questions';
export { default as WorkerService } from './workers';
export { HomepageCmsService } from './cms';
