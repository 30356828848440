<template>
    <div class="half-size">
        <div v-if="category.category_id" class="field">
            <label class="label">{{ $t('categories.option') }}</label>
            <div class="field-control">
                <multiselect v-model="category.parent_category"
                             :options="options"
                             label="name"
                             placeholder="Selecteer een categorie"
                             track-by="id"
                             @close="parentCategoryChanged" />
            </div>
        </div>
        <div v-if="category.category_id !== null" class="field">
            <label class="label">{{ $t('categories.header') }}</label>
            <div class="field-control">
                <multiselect v-model="category.sub_category_divider"
                             :options="dividers"
                             label="divider_label"
                             placeholder="Selecteer"
                             track-by="id"
                             @close="dividerChanged" />
            </div>
        </div>

        <div class="field">
            <label class="label">Naam</label>
            <div class="field-control">
                <input v-model="category.name" type="text" />
            </div>
        </div>
        <div class="field">
            <label class="label">Categorie pagina titel</label>
            <div class="field-control">
                <input v-model="category.title" type="text" />
            </div>
        </div>
        <div class="field">
            <label class="label">URL</label>
            <div class="field-control">
                <input v-model="category.url" type="text" />
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('categories.sort_method') }}</label>
            <div class="field-control">
                <multiselect v-model="sortMethod"
                             :options="sortMethods"
                             label="label"
                             placeholder="Selecteer een sorteer methode"
                             track-by="id"
                             @close="sortMethodChanged" />
            </div>
        </div>
        <div class="field">
            <label class="label">Mobiel / Tablet navigatie icoon</label>
            <div class="field-control">
                <multiselect v-model="icon"
                             :options="icons"
                             label="label"
                             class="category-icon"
                             placeholder="Selecteer een icoon"
                             track-by="path"
                             @close="iconChanged">
                    <template v-slot:singleLabel="{option}">
                        <span>
                            <img :alt="option.label" :src="option.path + '?inline'" class="filter-black">
                        </span>
                    </template>
                    <template v-slot:option="{option}">
                        <span>
                            <img :alt="option.label" :src="option.path + '?inline'" class="filter-white">
                        </span>
                    </template>
                </multiselect>
            </div>
        </div>
        <div v-if="category.category_id === null" class="field field--checkbox"
             @click="category.show_navbar = !category.show_navbar">
            <input :checked="category.show_navbar" type="checkbox" />
            <div class="field-control">
                <label>Tonen in menu</label>
            </div>
        </div>

        <div class="field field--image">
            <label class="label">Afbeelding</label>
            <div v-if="category.image === null" class="field-control">
                <label class="btn btn--blue" for="image-input">
                    {{ $t('images.add_image') }}
                </label>

                <input id="image-input"
                       accept="image/*"
                       name="images"
                       type="file"
                       @change="fileInputChange($event)">
            </div>
            <div v-else class="field-control">
                <div class="image-container">
                    <img :src="category.image.path || category.image" alt="" class="image">
                    <div class="icon" @click="category.image = null">
                        <img alt="close-icon" src="/svgs/close-icon.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import multiselect from 'vue-multiselect';
    import { CategoryService, DividerService } from '../../../services';

    export default {
        name: 'GeneralInformationTab',
        components: {
            multiselect,
        },
        methods: {
            dividerChanged() {
                if (this.category.sub_category_divider && this.category.sub_category_divider.id) {
                    this.category.sub_category_divider_id = this.category.sub_category_divider.id;
                }
            },
            sortMethodChanged() {
                this.category.sort_method_id = this.sortMethod.id;
            },
            iconChanged() {
                this.category.icon_path = this.icon.path;
            },
            parentCategoryChanged() {
                this.category.category_id = this.category.parent_category.id;
            },
            saveSettings() {
                this.$store.commit('categories/set_category', this.category);
            },
            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.category.image = e.target.result;
                };
            },
            fetchDividers() {
                if (this.category.category_id != null) {
                    DividerService.index(this.category.category_id).then(response => {
                        this.dividers = response.data;
                    });
                }
            },
        },
        props: {
            category: {
                required: true,
            },
        },
        watch: {
            category: {
                handler(category) {
                    this.$store.commit('categories/set_category', category);
                },
                deep: true,
            },
        },
        data() {
            return {
                options: [],
                dividers: [],
                subCategoryOptions: [],
                sortMethods: [],
                icons: [
                    {
                        label: 'aanbiedingen',
                        path: '/svgs/categories/aanbiedingen.svg',
                    },
                    {
                        label: 'desinfectie-stations',
                        path: '/svgs/categories/desinfectie-stations.svg',
                    },
                    {
                        label: 'gereedschapswanden',
                        path: '/svgs/categories/gereedschapswanden.svg',
                    },
                    {
                        label: 'karren',
                        path: '/svgs/categories/karren.svg',
                    },
                    {
                        label: 'kasten',
                        path: '/svgs/categories/kasten.svg',
                    },
                    {
                        label: 'kratten-en-bakken',
                        path: '/svgs/categories/kratten-en-bakken.svg',
                    },
                    {
                        label: 'lockers',
                        path: '/svgs/categories/lockers.svg',
                    },
                    {
                        label: 'oplossingen',
                        path: '/svgs/categories/oplossingen.svg',
                    },
                    {
                        label: 'opvangbakken',
                        path: '/svgs/categories/opvangbakken.svg',
                    },
                    {
                        label: 'stellingen',
                        path: '/svgs/categories/stellingen.svg',
                    },
                    {
                        label: 'stoelen',
                        path: '/svgs/categories/stoelen.svg',
                    },
                    {
                        label: 'werkbanken',
                        path: '/svgs/categories/werkbanken.svg',
                    },
                ],
                sortMethod: null,
                icon: null,
            };
        },
        mounted() {
            this.fetchDividers();
            this.sortMethod = this.category.sort_method;
            this.icon = {
                path: this.category.icon_path,
            };
        },
        async beforeRouteEnter(to, from, next) {
            const [sortMethodResponse, categoryResponse] = await Promise.all([
                CategoryService.sortMethods(),
                CategoryService.index(),
            ]);

            next(vm => {
                vm.options = categoryResponse.data;
                vm.sortMethods = sortMethodResponse.data;
            });
        },
    };
</script>
