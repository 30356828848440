export default {
    nl: {
        products: {
            products: 'Producten',
            product: 'Product',
            'new': 'Nieuw product',
            add_product: 'Nieuw product toevoegen',
            save: 'Product opslaan',
            name: 'Naam',
            product_line: 'Productlijn',
            product_line_head: 'Letters productlijn (e.g. OMP, GKP ..)',
            product_line_sub: 'Cijfers productlijn (e.g. 101, 102 ..)',
            size: 'Uitvoering (Size)',
            status: 'Status',
            price_indication: 'Indicatie',
            description: 'Beschrijving',
            is_configurable: 'Is te configureren',
            has_different_colors: 'Verschillende kleuren',
            has_discount: 'Korting',
            add_question: 'Nieuw vraag toevoegen',
            question: 'Vraag',
            question_type: 'Type',
            suggestion: 'Suggestie tekst',
            subcategory: 'Subcategorie',
            hide_on_main_categories: 'Verberg op hoofdcategorieën',
            price: 'Prijs',
            url: 'Url',
            relevant_products_url: 'Relevante producten url',
            link_accessoires: 'Accessoires url',
            short_tag: 'Korte omschrijving',
            meta: 'Meta',
            save_meta: 'Opslaan',
            save_quotation: 'Opslaan',
            save_success: 'Product succesvol opgeslagen',
            update_success: 'Wijzigingen succesvol opgeslagen',

            tariff_rate: 'Tarief {0} tot en met het aantal van',

            powerpage: 'Powerpage',
            store_powerpage: 'Powerpage opslaan',
            powerpage_enabled: 'Powerpage succesvol ingeschakeld',
            powerpage_disabled: 'Powerpage succesvol uitgeschakeld',

            quotation: {
                tab: 'Offerte',
                title: 'Titel',
                description: 'Omschrijving',
                button: 'Button Tekst',
                worker: 'Selecteer een werker',
            },
        },
    },
};
